import React from "react"
import { graphql } from "gatsby"
import { Row, Col } from "antd"

import Bio from "../components/bio"
import Summary from "../components/summary"
import Layout from "../components/layout"
import SEO from "../components/seo"

const TagTemplate = ({ pageContext, data, location }) => {
  const { tag } = pageContext
  const siteTitle = `${tag.toUpperCase()} 목록` //data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        <Bio />
        <p>No Data</p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle} work={tag}>
      <SEO title={siteTitle} image={posts[0].frontmatter.image} />
      <Row gutter={[24, 24]}>
        {posts.map(post => {
          return (
            <Col key={post.fields.slug} xl={8} lg={8} md={8} sm={12} xs={24}>
              <Summary post={post} />
            </Col>
          )
        })}
      </Row>
    </Layout>
  )
}

export default TagTemplate

export const pageQuery = graphql`
  query PostByTag($tag: [String]!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { tags: { in: $tag } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          description
          image
          name
          tags
          url
        }
      }
    }
  }
`
